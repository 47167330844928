/** @jsx jsx */
import { graphql } from "gatsby"
import { Fragment } from "react"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import Blocks from "../block-library/components/blocks"
import SEODetails from "../block-library/components/seo-details"
import Ingredients from "../block-library/ingredients"
import Layout from "../components/layout"

const IngredientsPage = ({
  data: {
    strapi: { ingredientsPage },
  },
}) => (
  <Layout title={ingredientsPage.title}>
    <SEODetails title={ingredientsPage.title} {...ingredientsPage.seoDetails} />

    {ingredientsPage.blocks?.length ? (
      <Blocks blocks={ingredientsPage.blocks} />
    ) : (
      <Fragment>
        <div sx={{ mt: [7, 9], mb: [9, 10] }}>
          <Container>
            <Heading as="h1" size="xl" mb={7}>
              {ingredientsPage.heading}
            </Heading>

            <ol
              sx={{
                display: "grid",
                gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
                rowGap: [6, 7],
                columnGap: [null, 5],
                my: 0,
                pl: 0,
                counterReset: "count",
                listStyleType: "none",
              }}
            >
              {ingredientsPage.numberedSections.map((numberedSection) => (
                <li
                  key={numberedSection.title}
                  sx={{
                    counterIncrement: "count",
                    pb: 5,
                    borderBottom: "1px",
                    "&:before": {
                      content: "counter(count, decimal-leading-zero)",
                      display: "block",
                      mb: 1,
                      fontSize: [null, 5],
                      fontFamily: "monospace",
                    },
                  }}
                >
                  <Heading as="h3" mb={[2, 4]} sx={{ fontSize: [4, 6] }}>
                    {numberedSection.title}
                  </Heading>

                  <p sx={{ my: 0 }}>{numberedSection.description}</p>
                </li>
              ))}
            </ol>
          </Container>
        </div>

        <Ingredients {...ingredientsPage.ingredients} />
      </Fragment>
    )}
  </Layout>
)

export const query = graphql`
  {
    strapi {
      ingredientsPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        heading
        numberedSections {
          title
          description
        }
        ingredients {
          ...IngredientsFragment
        }
        blocks {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksBannerUsp {
            ...BannerUspFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
        }
      }
    }
  }
`

export default IngredientsPage
